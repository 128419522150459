<template>
  <div class="equipment-verification-wrapper">
    <van-nav-bar
      title="检定计划"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-verification-form">
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-verification-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div v-for="item in list" :key="item.id" class="verification-item">
          <span class="verification-interval"
            >每<i>{{ item.verifyInterval }}</i
            >天</span
          >
          <div class="verification-title">{{ item.verifyItem }}</div>
          <div class="verification-bottom">
            <span class=""></span>
            <span class="verification-lastdate"
              >最近一次时间:{{ item.recentlyVerifyDate }}</span
            >
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getEquipmentVerificationPlans } from "@/api/basics/equipment";
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10
      },
      list: [],
      total: 0,
      loading: false,
      finished: false
    };
  },
  created() {},
  methods: {
    onLoad() {
      this.getData();
    },
    getData() {
      this.getEquipmentVerificationPlans();
    },
    getEquipmentVerificationPlans() {
      this.loading = true;
      getEquipmentVerificationPlans(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-verification-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-verification-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
  }
  .equipment-verification-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .verification-item {
      position: relative;
      padding: 0 16px;
      .verification-interval {
        position: absolute;
        right: 16px;
        height: 24px;
        width: 63px;
        background-color: #1676ff;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        line-height: 24px;
        font-size: 12px;
        transform: skewX(6deg);
        i {
          font-size: 16px;
          padding: 0 2px;
          vertical-align: top;
        }
      }
      .verification-bottom {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        align-items: center;
        .verification-mode {
          background-color: rgba(254, 119, 11, 0.1);
          color: #fe770b;
          padding: 4px 8px;
          border-radius: 2px;
        }
        .verification-lastdate {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
